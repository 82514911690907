<template>
    <div class="ShopMax">
        <!-- 顶部导航栏 -->
        <div class="SpecTopNav">
            <!-- 首页导航 -->
            <div class="SpecHomePage" @click="$router.push('/')">
                <img src="../../assets/CulturalHomePage/PomePage.png" alt="" style="width: 100%; height: 100%" />
            </div>
            <!-- 导航中间 -->
            <div class="SpecMiddle">
                <div class="SpecHeadline">随心购物</div>
                <!-- <div class="SpecHeadlineOne"> </div> -->
            </div>

            <!-- 个人中心导航 -->
            <div class="SpecPersonage" @click="Person()">
                <img src="../../assets/CulturalHomePage/personal.png" alt="" style="width: 100%; height: 100%" />
            </div>
        </div>
        <!-- 古镇列表 -->
        <!-- <div class="GuZhenList">
            <div v-for="(List, index) in List" :key="index" class="GuZhenBox" :class="{ ListCtived: ListCtive == index }" @click="QieHuan(index)">
                <div style="cursor: pointer;">{{ List.name }}</div>
            </div>
        </div> -->
        <!-- VR背景 -->
        <iframe src="https://www.720yun.com/vr/43ajOs4ktv0" style="width: 100%;height: 100%;position: relative;top: -80px;" frameborder="no"></iframe>
        <!-- <TiShiBox ref="childRef"></TiShiBox> -->
    </div>
</template>
<script>
// import TiShiBox from '../Public/TiShiBox.vue'
export default {
    data () {
        return {
            ListCtive: 0
        }
    },
    components: {
        // TiShiBox
    },
    created () {

    },
    mounted () {
    },
    methods: {
        QieHuan (index) {
            if (index > 1) {
                // ElMessage.warning('敬请期待~')
                this.$refs.childRef.ChuFa()
            } else {
                this.ListCtive = index
            }

        },
        Person () {
            // 进入页面就判断是否有token  如果没有那就继续登陆 如果有就进入个人中心
            if (this.$cookies.get("token") == null) {
                this.$router.push('/login')
            } else {
                this.$router.push('/personalCenter')
            }
        },
    },
};
</script>

<style lang="less" scoped>
.ShopMax {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    .SpecTopNav {
        width: 60%;
        height: 80px;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        align-items: center;
        z-index: 999;
        .SpecHomePage {
            height: 7vh;
            width: 7.5vh;
            border-radius: 50%;
            cursor: pointer;
            z-index: 9999;
        }

        .SpecMiddle {
            width: 480px;
            height: 70px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-left: 80px;
            background: url(../../assets/SpecialScenic/topstrap.png);
            background-size: 100% 100%;
            margin-top: -10px;
            color: rgb(67, 220, 255);
            z-index: 9999;
            .SpecHeadline {
                font-size: 2.8vh;
                // margin-left: 60px;
            }

            .SpecHeadlineOne {
                width: 270px;
                height: 20px;
                font-size: 2.5vh;
                margin-left: 20px;
            }
        }
        .SpecPersonage {
            height: 7vh;
            width: 7.5vh;
            cursor: pointer;
            z-index: 9999;
        }
    }
}
// .GuZhenList {
//     position: fixed;
//     z-index: 999;
//     bottom: 6.5vh;
//     left: 25%;
//     width: 50%;
//     height: 50px;
//     margin: 0 auto;
//     display: flex;
//     justify-content: space-between;
//     align-items: center;
//     background-color: rgba(0, 0, 0, 0.3);
//     .GuZhenBox {
//         width: 20%;
//         display: flex;
//         justify-content: center;
//         align-items: center;
//         color: white;
//     }
//     .ListCtived {
//         color: #f88008;
//     }
// }
</style>